<!-- <div class="toast"
     [@fadeAnimation]="{value: animationState, params:
        { fadeIn: toastConfig.animation.fadeIn, fadeOut: toastConfig.animation.fadeOut }}"
     (@fadeAnimation.done)="onFadeFinished($event)">
  <mat-icon>{{ iconType }}</mat-icon>
  <div>{{ data.text }}</div>
  <mat-icon (click)="close()">close</mat-icon>
</div> -->

<div class="toast" [@fadeAnimation]="{value: animationState, params:
  { fadeIn: toastConfig.animation.fadeIn, fadeOut: toastConfig.animation.fadeOut }}"
(@fadeAnimation.done)="onFadeFinished($event)">
  <div class="toast-header bg-{{data.type}} text-white">
      <i data-feather="bell"></i>
      <strong class="mr-auto">{{data.title}}</strong>
      <small class="text-muted ml-2" style="color: #ffffff!important;">just now</small>
      <button class="ml-2 mb-1 close" type="button" (click)="close()">
          <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="toast-body">{{data.text}}</div>
</div>
