import { Injectable } from '@angular/core';

@Injectable()
export class Country {
  columns: {
    rptDate: {
      title: 'Country',
      type: 'string',
    },
    requestRecieved: {
      title: 'Request',                   // country  Action Country Request Bid Response Bid Percentage Loss Opportunity
      filter: true,
      sortDirection: 'desc',
    },
    bid_response: {
      title: 'Bid Response',
      filter: true
    },
    bid_per: {
      title: 'Bid Percentage',
      filter: true
    },
    loss_req: {
      title: 'Loss Opportunity',
      filter: true,
    },
  }
}

// export class AdSize {
//   columns: {
//     rptDate: {
//       title: 'AD Size',
//       type: 'string',
//     },
//     requestRecieved: {
//       title: 'Request',                   // country  Action Country Request Bid Response Bid Percentage Loss Opportunity
//       filter: true,
//       sortDirection: 'desc',
//     },
//     bid_response: {
//       title: 'Bid Response',
//       filter: true
//     },
//     bid_per: {
//       title: 'Bid Percentage',
//       filter: true
//     },
//     loss_req: {
//       title: 'Loss Opportunity',
//       filter: true,
//     },
//   }
// }
// export class operatingSystem {
//   columns: {
//     os: {
//       title: 'Operating System',
//       type: 'string',
//     },
//     requestRecieved: {
//       title: 'Request',                   // country  Action Country Request Bid Response Bid Percentage Loss Opportunity
//       filter: true,
//       sortDirection: 'desc',
//     },
//     bid_response: {
//       title: 'Bid Response',
//       filter: true
//     },
//     bid_per: {
//       title: 'Bid Percentage',
//       filter: true
//     },
//     loss_req: {
//       title: 'Loss Opportunity',
//       filter: true,
//     },
//   }
// }

// export class devicetype {
//   columns: {
//     deviceType: {
//       title: 'Device Type',
//       type: 'string',
//     },
//     requestRecieved: {
//       title: 'Request',                   // country  Action Country Request Bid Response Bid Percentage Loss Opportunity
//       filter: true,
//       sortDirection: 'desc',
//     },
//     bid_response: {
//       title: 'Bid Response',
//       filter: true
//     },
//     bid_per: {
//       title: 'Bid Percentage',
//       filter: true
//     },
//     loss_req: {
//       title: 'Loss Opportunity',
//       filter: true,
//     },
//   }
// }
