import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customize-mapping-box',
  templateUrl: './customize-mapping-box.component.html',
  styleUrls: ['./customize-mapping-box.component.css']
})
export class CustomizeMappingBoxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
