import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Globals } from '@app/_helpers/globals';

@Component({
  selector: 'app-list-management-action',
  templateUrl: './list-management-action.component.html',
  styleUrls: ['./list-management-action.component.css']
})
export class ListManagementActionComponent implements OnInit {
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() toggleAudienceDataEvent = new EventEmitter();
  @Output() toggleDomainDataEvent = new EventEmitter();
  @Output() togglePullTagEvent = new EventEmitter();
  @Output() deleteDomainDataEvent = new EventEmitter();
  @Output() downloadFileEvent = new EventEmitter();
  publisherFlag:any = false;
  ipListFlag:any = false;
  keywordFlag:any = false;
  audienceFlag:any = false;
  domainFlag:any = false;
  deviceIdFlag:any = false;
  postalCodeFlag:any = false;
  pageUrlFlag:any = false;
  tagIdFlag:any = false;
  constructor(public globals:Globals) { }

  ngOnInit(): void {
    let domainCheck = window.location.href.split('#')[1]
    switch (domainCheck) {
      case "/pages/list-management/publisher-list":
      this.publisherFlag = true;
      break;
      case "/pages/list-management/ip-list":
      this.ipListFlag = true;
      break;
      case "/pages/list-management/keyword-list":
      this.keywordFlag = true;
      break;
      case "/pages/list-management/audience-list":
      this.audienceFlag = true;
      break;
      case "/pages/list-management/domain-list":
      this.domainFlag = true;
      break;
      case "/pages/list-management/device-id":
      this.deviceIdFlag = true;
      break;
      case "/pages/list-management/postal-code":
      this.postalCodeFlag = true;
      break;
      case "/pages/list-management/page-url":
      this.pageUrlFlag = true;
      break;
      case "/pages/list-management/tag-id":
      this.tagIdFlag = true;
      break;
    }
  }

  toggleAudienceModal(rowData){
    let audienceModal = true
    this.toggleAudienceDataEvent.emit({rowData, audienceModal})
  }

  togglePullTagModal(rowData) {
    let pullTagModel = true
    this.togglePullTagEvent.emit({ rowData, pullTagModel })
  }

  toggleDomainModal(rowData){
    let domainModal = true
    this.toggleDomainDataEvent.emit({ rowData, domainModal })
  }
  deleteDomainById(rowData) {
    let deleteDomainRecord = true
    this.deleteDomainDataEvent.emit({ rowData, deleteDomainRecord })
  }
  downloadFile(rowData){
    let downloadFileEvent = true
    this.downloadFileEvent.emit({ rowData, downloadFileEvent })
  }

}
