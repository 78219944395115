import { Component } from '@angular/core';
import { Globals } from './_helpers/globals';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ingenious-plex';

  constructor(public globals:Globals){}

  ngOnInit(){
    console.log(this.globals);
    
  }
}
