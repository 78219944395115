<!-- Publisher list -->
<div *ngIf="publisherFlag" (click)="toggleDomainModal(rowData)">
  <i class="fas fa-edit" style="margin-top: -10px; cursor: pointer;" title="Edit Publisher"></i>
</div>
<!-- <div *ngIf="tagIdFlag" (click)="deleteDomainById(rowData)">
  <i class="fas fa-times-circle" style="margin-top: -10px; cursor: pointer;" title="Delete Domain"></i>
</div>
<div *ngIf="tagIdFlag" (click)="downloadFile(rowData)">
  <i class=" fas fa-file-download" style="margin-top: -10px; cursor: pointer;" title="Download Domain"></i>
</div> -->

<!-- keyword list -->
<div *ngIf="keywordFlag" (click)="toggleDomainModal(rowData)">
  <i class="fas fa-edit" style="margin-top: -10px; cursor: pointer;" title="Edit Keyword"></i>
</div>

<!-- Audience -->
<div *ngIf="audienceFlag"  (click)="toggleAudienceModal(rowData)">
  <i class="fas fa-edit" style="margin-top: -10px; cursor: pointer;" title="Edit Audience"></i>
</div>
<div *ngIf="audienceFlag" (click)="togglePullTagModal(rowData)">
  <i class="fa fa-file" style="margin-top: -10px; cursor: pointer;" title="View Tag"></i>
</div>


<!-- Domain -->
<div *ngIf="domainFlag" (click)="toggleDomainModal(rowData)">
  <i class="fas fa-edit" style="margin-top: -10px; cursor: pointer;" title="Edit Domain"></i>
</div>
<div *ngIf="domainFlag && globals.privilegeId !='3'" (click)="deleteDomainById(rowData)">
  <i class="fas fa-times-circle" style="margin-top: -10px; cursor: pointer;" title="Delete Domain"></i>
</div>
<div *ngIf="domainFlag" (click)="downloadFile(rowData)">
  <i class=" fas fa-file-download" style="margin-top: -10px; cursor: pointer;" title="Download Domain"></i>
</div>


<!-- deviceId -->

<div *ngIf="deviceIdFlag" (click)="toggleDomainModal(rowData)">
  <i class="fas fa-edit" style="margin-top: -10px; cursor: pointer;" title="Edit Device Id"></i>
</div>
<div *ngIf="deviceIdFlag && globals.privilegeId !='3'" (click)="deleteDomainById(rowData)">
  <i class="fas fa-times-circle" style="margin-top: -10px; cursor: pointer;" title="Delete Device Id"></i>
</div>
<div *ngIf="deviceIdFlag" (click)="downloadFile(rowData)">
  <i class=" fas fa-file-download" style="margin-top: -10px; cursor: pointer;" title="Download Device Id"></i>
</div>

<!-- Postal Code -->

<div *ngIf="postalCodeFlag" (click)="toggleDomainModal(rowData)">
  <i class="fas fa-edit" style="margin-top: -10px; cursor: pointer;" title="Edit Postal Code"></i>
</div>
<div *ngIf="postalCodeFlag  && globals.privilegeId !='3'" (click)="deleteDomainById(rowData)">
  <i class="fas fa-times-circle" style="margin-top: -10px; cursor: pointer;" title="Delete Postal Code"></i>
</div>
<div *ngIf="postalCodeFlag" (click)="downloadFile(rowData)">
  <i class=" fas fa-file-download" style="margin-top: -10px; cursor: pointer;" title="Download Postal Code"></i>
</div>

<!-- page url -->

<div *ngIf="pageUrlFlag" (click)="toggleDomainModal(rowData)">
  <i class="fas fa-edit" style="margin-top: -10px; cursor: pointer;" title="Edit Page URL"></i>
</div>
<div *ngIf="pageUrlFlag  && globals.privilegeId !='3'" (click)="deleteDomainById(rowData)">
  <i class="fas fa-times-circle" style="margin-top: -10px; cursor: pointer;" title="Delete Page URL"></i>
</div>
<div *ngIf="pageUrlFlag" (click)="downloadFile(rowData)">
  <i class=" fas fa-file-download" style="margin-top: -10px; cursor: pointer;" title="Download Page URL"></i>
</div>


<!-- tag Id -->
<div *ngIf="tagIdFlag" (click)="toggleDomainModal(rowData)">
  <i class="fas fa-edit" style="margin-top: -10px; cursor: pointer;" title="Edit Tag Id"></i>
</div>
<div *ngIf="tagIdFlag" (click)="deleteDomainById(rowData)">
  <i class="fas fa-times-circle" style="margin-top: -10px; cursor: pointer;" title="Delete Tag Id"></i>
</div>
<div *ngIf="tagIdFlag" (click)="downloadFile(rowData)">
  <i class=" fas fa-file-download" style="margin-top: -10px; cursor: pointer;" title="Download Tag Id"></i>
</div>


<!-- IP list management -->
<div *ngIf="ipListFlag" (click)="toggleDomainModal(rowData)">
  <i class="fas fa-edit" style="margin-top: -10px; cursor: pointer;" title="Edit IP list"></i>
</div>
<div *ngIf="ipListFlag" (click)="deleteDomainById(rowData)">
  <i class="fas fa-times-circle" style="margin-top: -10px; cursor: pointer;" title="Delete IP list"></i>
</div>
<div *ngIf="ipListFlag">
  <a href="{{rowData.s3Path}}" target="_blank"  style="margin-top: -10px; cursor: pointer;color:black" title="Download IP list">
    <i class=" fas fa-file-download" style="margin-top: -10px; cursor: pointer;color:black"></i>
  </a>
</div>
